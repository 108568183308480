import {Authenticator, Image, View, useTheme} from "@aws-amplify/ui-react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import {Box, ThemeProvider, Typography, Grid} from "@mui/material";

import "./assets/styles/base.css";
// import "./assets/styles/heading.css";
import "./assets/styles/typography.css";
import "./App.css";

import {defaultTheme} from "./core/theme";
import Home from "./pages/home/home";
import ComplaintDetails from "./pages/submission-details/SubmissionDetails";
import ComplaintsTracker from "./pages/tracker/Tracker";
import NewComplaint from "./pages/new-submission/NewSubmission";

import LogoImage from "./assets/images/PWCLogo.svg";
// import LogoImage from "./assets/images/aspen_logo.svg";

import {AppRoutes} from "./config/routesConfig";
import Layout from "./pages/layout";

const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={< Layout />}>
        <Route index element={< Home />}/>
        <Route path={AppRoutes.crisisManagement.children?.tracker.path || ""} element={< ComplaintsTracker />}/>
        <Route path={AppRoutes.crisisManagement.children?.details.path || ""} element={< ComplaintDetails />}/>
        <Route path={AppRoutes.demoTools.children?.newSubmission.path || ""} element={< NewComplaint />}/>

        // add the new route here based on the route config at src/config/routesConfig.ts

    </Route>
));

export default function App() {
    const {tokens} = useTheme();
    const components = {
        Header() {
            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Grid container alignItems={"center"} justifyContent={"center"} mt={2} mb={2}>
                        <Grid item pr={1} alignItems={"center"} alignContent={"center"} display={"flex"}>
                            <Image src={LogoImage} alt="PwC" width="50px"/>
                        </Grid>
                        <Grid alignItems={"center"} alignContent={"center"} >
                            <Typography variant="h4"fontWeight={400}>Underwriting Intelligence</Typography>
                        </Grid>
                    </Grid>
                </View>
            );
        },
        Footer() {
            const {tokens} = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    <Typography variant="caption">
                        &copy; 2024 PricewaterhouseCoopers UK. All Rights Reserved.
                    </Typography>
                </View>
            );
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Authenticator components={components} hideSignUp>
                <RouterProvider router={router}/>
            </Authenticator>
        </ThemeProvider>
    );
}
