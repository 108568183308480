// SubmissionDetailsCard.tsx
import React from 'react';
import { Box, Card, CardContent, Typography, Divider, Chip, Grid } from '@mui/material';
import { AgeChip } from '../../../components';
import { getStatusColor, getFlagColor } from '../constants';
// import PrioritySlider from './PrioritySlider';
import ManageSubmissionModal from '../../tracker/components/ManageSubmissionModal';

interface SubmissionDetailsCardProps {
    complaint: any;
}

const SubmissionDetailsCard: React.FC<SubmissionDetailsCardProps> = ({ complaint }) => {




    return (
        <Card variant="outlined" style={{ padding: '8px' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h6" gutterBottom>
                            Submission details
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={2}>
                        <ManageSubmissionModal />
                    </Grid> */}
                </Grid>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>

                    
                    {/* <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Processing stage:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Chip
                            label={complaint.submission_status}
                            size="small"
                            color={getStatusColor(complaint.submission_status)}
                        />
                    </Grid> */}

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Created:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <AgeChip dateCreated={complaint?.date_created} />
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Brokerage Name:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{complaint?.submission_insuredname?.InsuredCompany}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Insured Name:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{complaint?.submission_insuredname?.InsuredCompany}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Total Value:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">
                            {complaint?.submission_totalvalue?.CurrencyCode} {complaint?.submission_totalvalue?.Value}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Coverage:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{complaint?.submission_coverage?.Coverage}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Country of Exposure:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {complaint?.submission_countryofexposure?.Countries?.map((country: any, index: number) => (
                            <Box key={index} mb={1}>
                                <Chip label={country.CountryOfExposure} color={getFlagColor(country.Flag)} size="small" />
                                <Typography variant="body2">{country.Locations.join(', ')}</Typography>
                                {/* <Typography variant="body2">{country.CurrencyCode} {country.Value}</Typography> */}
                            </Box>
                        ))}
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Occupancy:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {complaint?.submission_occupancy?.Occupancies?.map((occupancy: any, index: number) => (
                            <Box key={index} mb={1}>
                                {/* <Typography variant="body2">{occupancy.Occupancy}</Typography> */}
                                <Chip
                                    label={occupancy.Occupancy}
                                    size="small"
                                    color={getFlagColor(occupancy.Flag)}
                                />
                                {/* <Typography variant="body2">{occupancy.CurrencyCode} {occupancy.Value}</Typography> */}
                            </Box>
                        ))}
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Policy Limit:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{complaint?.submission_policy?.PolicyLimit}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Policy Deductible/Excess:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{complaint?.submission_policy?.PolicyDeductibleExcess}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Policy Period:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">{complaint?.submission_policy?.PolicyPeriod}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="subtitle2" fontWeight="bold">Sub-limits:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2">TBA</Typography>
                    </Grid>

                </Grid>
            </CardContent>
        </Card>
    );
};

export default SubmissionDetailsCard;