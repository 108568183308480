import React from "react";
import { AccountCircle, Notifications, Logout } from "@mui/icons-material";
import {
  Box,
  Badge,
  IconButton,
  Toolbar,
  Typography,
  Chip,
  Menu,
  MenuItem,
  Divider,
  useTheme
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import SearchIcon from "@mui/icons-material/Search";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import UserAttribute from "./userAttributes";

import { Search, SearchIconWrapper, StyledInputBase } from "./search";

import LogoImage from "../assets/images/PWCLogo.svg";

export const AppHeader = () => {
  const theme = useTheme();
  // const [normalStringUsername, setNormalStringUsername] = React.useState("");

  // React.useEffect(() => {
  //   const getCurrentUsername = async () => {
  //     try {
  //       const user = await getCurrentUser();
  //       setNormalStringUsername(String(user.username));
  //     } catch (error) {
  //       console.log("Error getting current user:", error);
  //     }
  //   };

  //   getCurrentUsername();
  // }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  async function logoutUser() {
    await signOut();
    navigate("/", { replace: true });
  }

  return (
    <AppBar
      sx={{
        bgcolor: theme.palette.pwc.main,
        boxShadow: "0 0 0 0",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      position="fixed"
      enableColorOnDark
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "40px",
            }}
            alt="PWC"
            src={LogoImage}
          />
          <Typography
            component="p"
            variant="subtitle1"
            color="inherit"
            noWrap
            sx={{
              display: "block",
              flexGrow: 1,
              marginLeft: "8px",
              fontSize: "1.2em",
              letterSpacing: "-0.5px",
            }}
          >
            Underwriting AI
          </Typography>
          
        </Box>
        {/* Search Bar */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Search
            sx={{
              border: "none",
              // backgroundColor: theme.palette.pwc.light,
              // "&:hover": {
              //   backgroundColor: "#428563",
              // },
            }}
          >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              disabled
              placeholder="Search (disabled)"
              inputProps={{
                "aria-label": "search",
              }}
            />
          </Search>
          <IconButton disabled color="inherit">
            <Badge badgeContent={0} color="primary">
              <Notifications />
            </Badge>
          </IconButton>
          
          <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                
                <Box py={1} px={2}>
                  <Typography>
                    Logged in as:
                  </Typography>
                  <Typography variant="body2">
                    <UserAttribute attribute="fullName" />
                  </Typography>
                  <Typography variant="caption">
                    <UserAttribute attribute="email" />
                  </Typography>
                </Box>
                <Divider sx={{mb: "6px"}}/>
                <MenuItem onClick={logoutUser}>
                  <Logout color="primary" fontSize="small" sx={{mr: "4px"}}/>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          
        </Box>
      </Toolbar>
    </AppBar>
  );
};

// export default function Component() {
//   return <AppHeader />;
// }
