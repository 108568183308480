import{ Box, Skeleton } from '@mui/material';

const LoadingComponent = () => {
    return (
        <Box>
                <Skeleton variant="rounded" animation="wave" width={300} height={30} sx={{mb:2}} />
                <Skeleton variant="rounded"  height={50} sx={{mb:2}} />
                <Skeleton variant="rounded" animation="wave" width={500} height={100} sx={{mb:2}} />
                <Skeleton variant="rounded" width={500} height={80} sx={{mb:2}} />
                <Skeleton variant="rounded" animation="wave" width={500} height={30} sx={{mb:2}} />
            
        </Box>
    );
};

export default LoadingComponent;