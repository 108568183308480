import React from 'react';
import { Box, Typography, Tooltip, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';

interface BarSegment {
    label: string;
    value: number;
}

interface HorizontalStackedBarProps {
    data: BarSegment[];
    current: number;
    limit: number;
    height?: number;
}

const HorizontalStackedBar: React.FC<HorizontalStackedBarProps> = ({
    data,
    current,
    limit,
    height = 40,
}) => {
    const theme = useTheme();
    const total = data.reduce((sum, segment) => sum + segment.value, 0);
    const maxValue = Math.max(total, limit, current);

    const getPosition = (value: number) => `${(value / maxValue) * 100}%`;

    const getSegmentColor = (index: number) => {
        const baseColor = theme.palette.primary.main;
        const baseAlpha = 0.15;
        const alphaIncrement = (1 - baseAlpha) / (data.length - 1);
        return alpha(baseColor, baseAlpha + index * alphaIncrement);
    };

    return (
        <Box sx={{ width: '100%', p: 2 }}> {/* Added padding */}

            {/* Legend */}
            <Box mb={2} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2 }}>
                {data.map((segment, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 16, height: 16, bgcolor: getSegmentColor(index), mr: 1 }} />
                        <Typography variant="caption">{segment.label}</Typography>
                    </Box>
                ))}
            </Box>
            <Box sx={{ position: 'relative', width: '100%', height, mb: 8 }}> {/* Increased bottom margin */}


                {/* Stacked Bar */}
                <Box
                    sx={{
                        position: 'relative', // Changed to relative
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        borderRadius: 1,
                        overflow: 'hidden',
                        border: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    {data.map((segment, index) => (
                        <Tooltip key={index} title={`${segment.label}: ${segment.value}`} arrow>
                            <Box
                                sx={{
                                    width: `${(segment.value / maxValue) * 100}%`,
                                    bgcolor: getSegmentColor(index),
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography variant="caption" sx={{ color: theme.palette.text.primary, fontWeight: 'bold' }}>
                                    {segment.value}
                                </Typography>
                            </Box>
                        </Tooltip>
                    ))}
                </Box>

                {/* Indicators Container */}
                <Box sx={{ position: 'absolute', top: '100%', left: 0, right: 0, height: 60, mt: 1 }}>
                    {/* Current Value Indicator */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: getPosition(current),
                            width: 0,
                            height: 0,
                            borderLeft: '6px solid transparent',
                            borderRight: '6px solid transparent',
                            borderBottom: `12px solid ${theme.palette.info.main}`,
                            transform: 'translateX(-50%)',
                        }}
                    />
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            top: 16,
                            left: getPosition(current),
                            transform: 'translateX(-50%)',
                            color: theme.palette.info.main,
                            maxWidth: '60px', // Limit text width
                            textAlign: 'center', // Center align text
                            wordWrap: 'break-word', // Allow word breaking
                        }}
                    >
                        Current: {current}
                    </Typography>

                    {/* Limit Indicator */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: getPosition(limit),
                            width: 0,
                            height: 0,
                            borderLeft: '6px solid transparent',
                            borderRight: '6px solid transparent',
                            borderBottom: `12px solid ${theme.palette.error.main}`,
                            transform: 'translateX(-50%)',
                        }}
                    />
                    <Typography
                        variant="caption"
                        sx={{
                            position: 'absolute',
                            top: 16,
                            left: getPosition(limit),
                            transform: 'translateX(-50%)',
                            color: theme.palette.error.main,
                            maxWidth: '60px', // Limit text width
                            textAlign: 'center', // Center align text
                            wordWrap: 'break-word', // Allow word breaking
                        }}
                    >
                        Limit: {limit}
                    </Typography>
                </Box>
            </Box>


        </Box>
    );
};

export default HorizontalStackedBar;