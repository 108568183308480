import { useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Alert,

} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { fetchAuthSession } from "aws-amplify/auth";
import { BASE_ENGINE_API_URL } from "../../../api";
import React from "react";
import Notification from "../../../components/Notification";
import useNotification from "../../../hooks/useNotification"; // Import the custom hook


async function uploadFile(file: File, handleOpenDialog: () => void) {
  try {
    console.log("uploadFile called with file:", file);
    if (file.type !== "text/plain") {
      console.error("Invalid file type. Only .txt files are allowed.");
      handleOpenDialog();
      return null;
    }

    console.log("Fetching Auth Session");
    var cognitoTokens = (await fetchAuthSession()).tokens;
    let identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    const requestBody = {
      type: "upload_audiorecording",
    };

    const presignResponse = await fetch(`${BASE_ENGINE_API_URL}/presign`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${identityToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!presignResponse.ok) {
      throw new Error(
        `Failed to get presigned URL. Status: ${presignResponse.status}`
      );
    }

    let presignedUrl = await presignResponse.text();
    presignedUrl = presignedUrl.replace(/['"]+/g, ""); // Remove any quotes around the URL
    console.log("Cleaned Presigned URL:", presignedUrl);

    const uploadResponse = await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    });

    if (!uploadResponse.ok) {
      const errorText = await uploadResponse.text();
      throw new Error(
        `Failed to upload file. Status: ${uploadResponse.status}, Response: ${errorText}`
      );
    }

    console.log("File uploaded successfully!");
    return "File uploaded successfully!" + uploadResponse.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
}

const FileUpload: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const clearFile = () => {
    setSelectedFile(null);
  };
  const [fileName, setFileName] = useState<String | null>("");
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const { notification, showNotification, closeNotification } =
    useNotification();
  const [loading, setLoading] = useState<boolean>(true);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleUploadClick = async () => {
    if (!selectedFile) return;
    showNotification("Your file upload has started.", "info");

    const confirmation = await uploadFile(selectedFile, handleOpenDialog);
    if (confirmation) {
      setUploadUrl(confirmation);
      showNotification("Your file upload was successful.", "success");
      clearFile();
    } else {
      showNotification("Failed to upload file.", "error");
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <Grid container spacing={3} mt={0}>
      {/* upload section */}
      <Grid item md={12}>
        <Card
          variant="outlined"
          style={{
            padding: "24px",
          }}
        >
          <Grid item md={12}>
            <Typography variant="h6" gutterBottom>
              Upload email
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body2" gutterBottom>
              Please upload the Brokers email
            </Typography>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              flexGrow: 1,
              marginTop: "16px",
              border: "1px dashed rgba(0, 0, 0, 0.12)",
            }}
            item
            container
            justifyContent="center"
            alignItems="center"
            py={5}
            md={12}
          >
            {!selectedFile && (
              <Button
                style={{}}
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                size="large"
              >
                <FolderOpenIcon sx={{ mr: 1 }} />
                Select a file
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Button>
            )}
            {selectedFile && fileName && (
              <Alert icon={false} severity="info">
                <b>Selected file:</b> {fileName}
                <IconButton onClick={clearFile}>
                  <RemoveCircleIcon />
                </IconButton>
              </Alert>
            )}
          </Grid>
          <Grid item md={12} mt={2} container justifyContent="flex-end">
            {selectedFile && fileName && (
              <Button
                size="medium"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<FileUploadIcon />}
                onClick={handleUploadClick}
              >
                Upload
              </Button>
            )}
          </Grid>
        </Card>
      </Grid>
      {/* Notifications, alerts and dialog boxes  */}
      {/* invalid file type dialog alert */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant="h5">Invalid file type</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              Only .txt files are allowed.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Reusable Notification component */}
      <Notification
        open={notification.open}
        message={notification.message}
        onClose={closeNotification}
        severity={notification.severity}
      />
    </Grid>
  );
};
export default FileUpload;
