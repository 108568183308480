import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HeadingWithIcon from "../../../components/HeadingWithIcon";

interface TechnicalPricingDetailsCardProps {
  complaint: any;
}

const TechnicalPricingPricedCard: React.FC<
  TechnicalPricingDetailsCardProps
> = ({ complaint }) => {
  return (
    <Grid container spacing={3} sx={{ padding: 2 }}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <HeadingWithIcon
              icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
              variant="h6"
              fontWeight="bold"
            >
              Recieved Pricing
            </HeadingWithIcon>
            <Divider sx={{ mb: 2 }} />

            <Typography variant="body1" gutterBottom>
              For a tech startup, the Business Owners Policy (BOP) offers
              comprehensive coverage at an annual premium of $3,200, which can
              be broken down into monthly payments of $266.67. This policy is
              designed to address the unique risks and requirements of a
              technology-focused business.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Coverage Details:
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              <ListItem>
                <ListItemText
                  primary="Property Coverage"
                  secondary="The policy includes $1,000,000 in property coverage, protecting the business's physical assets, such as office equipment, computers, and furniture."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="General Liability"
                  secondary="General liability coverage is set at $1,000,000 per occurrence and $2,000,000 in aggregate. This coverage protects the business against claims of bodily injury, property damage, and personal injury occurring on the business premises or as a result of business operations."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Business Interruption"
                  secondary="With $200,000 in business interruption coverage, the policy helps cover lost income and operating expenses if the business is forced to shut down temporarily due to a covered loss."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Cyber Liability"
                  secondary="The policy provides $500,000 in cyber liability coverage, which is crucial for a tech startup. This coverage helps protect against data breaches, cyberattacks, and other digital threats."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Directors and Officers (D&O) Liability"
                  secondary="D&O liability coverage of $1,000,000 is included, safeguarding the personal assets of the company's directors and officers and covering legal costs associated with claims of wrongful acts in their managerial capacity."
                />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              Deductibles:
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              <ListItem>
                <ListItemText primary="Property: $1,500" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Liability: $500" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Cyber Liability: $1,000" />
              </ListItem>
              <ListItem>
                <ListItemText primary="D&O Liability: $1,000" />
              </ListItem>
            </List>

            <Typography variant="h6" gutterBottom>
              Discounts Applied:
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <List>
              <ListItem>
                <ListItemText
                  primary="Tech Industry Association Membership Discount"
                  secondary="A 7% discount is applied for membership in a tech industry association, reflecting the insurer's recognition of the reduced risk associated with businesses that adhere to industry standards and best practices."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="Multi-Policy Discount"
                  secondary="An additional 5% discount is provided for bundling multiple policies with the same insurer, offering cost savings and simplified management of insurance coverage."
                />
              </ListItem>
            </List>

            <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
              Overall, this BOP offers robust protection tailored to the needs
              of a tech startup, ensuring comprehensive coverage for both
              traditional and emerging risks while also providing cost-saving
              discounts.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TechnicalPricingPricedCard;
