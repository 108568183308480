import { createTheme } from '@mui/material/styles';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
    interface Palette {
        aspen: Palette['primary'];
    }

    interface PaletteOptions {
        aspen?: PaletteOptions['primary'];
    }
    interface Palette {
        pwc: Palette['primary'];
    }

    interface PaletteOptions {
        pwc?: PaletteOptions['primary'];
    }
    interface Palette {
        sidebar: Palette['primary'];
    }
    interface PaletteOptions {
        sidebar?: PaletteOptions['primary'];
    }
}
export const defaultTheme = createTheme({
    palette: {
        pwc: {
            main: '#D04A02',
            light: '#DEDEDE',
            dark: '#295477',
            contrastText: '#242105',
        },
        sidebar: {
            main: '#E3D026',
            light: '#f3f3f3',
            dark: '#A29415',
            contrastText: '#242105',
        },
        primary: {
            main: "#D04A02",
            light: "#5cb78b",
            dark: "#295477",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#f50057",
            light: "#ff5983",
            dark: "#c51162",
            contrastText: "#ffffff",
        },
        error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
            contrastText: "#ffffff",
        },
        warning: {
            main: "#ffa000",
            light: "#ffb300",
            dark: "#ff8f00",
            contrastText: "#000000",
        },
        info: {
            main: "#1976d2",
            light: "#63a4ff",
            dark: "#004ba0",
            contrastText: "#ffffff",
        },
        success: {
            main: "#388e3c",
            light: "#66bb6a",
            dark: "#2e7d32",
            contrastText: "#ffffff",
        },
        text: {
            primary: "#252525",
            secondary: "#757575",
            disabled: "#bdbdbd",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
        divider: "#e0e0e0",
        action: {
            active: "#252525",
            hover: "#f5f5f5",
            selected: "#eeeeee",
            disabled: "#bdbdbd",
            disabledBackground: "#e0e0e0",
            focus: "#e0e0e0",
            hoverOpacity: 0.08,
            selectedOpacity: 0.16,
            disabledOpacity: 0.38,
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h3: {
            fontSize: "1.75rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: 600,
            letterSpacing: -0.7
        },

    }
})

export const aspenTheme = createTheme({
    palette: {
        aspen: {
            main: '#E3D026',
            light: '#E9DB5D',
            dark: '#A29415',
            contrastText: '#242105',
        },
        sidebar: {
            main: '#E3D026',
            light: '#f3f3f3',
            dark: '#A29415',
            contrastText: '#242105',
        },
        primary: {
            main: "#34805A",
            light: "#5cb78b",
            dark: "#295477",
            contrastText: "#ffffff",
        },
        secondary: {
            main: "#f50057",
            light: "#ff5983",
            dark: "#c51162",
            contrastText: "#ffffff",
        },
        error: {
            main: "#d32f2f",
            light: "#ef5350",
            dark: "#c62828",
            contrastText: "#ffffff",
        },
        warning: {
            main: "#ffa000",
            light: "#ffb300",
            dark: "#ff8f00",
            contrastText: "#000000",
        },
        info: {
            main: "#1976d2",
            light: "#63a4ff",
            dark: "#004ba0",
            contrastText: "#ffffff",
        },
        success: {
            main: "#388e3c",
            light: "#66bb6a",
            dark: "#2e7d32",
            contrastText: "#ffffff",
        },
        text: {
            primary: "#252525",
            secondary: "#757575",
            disabled: "#bdbdbd",
        },
        background: {
            default: "#ffffff",
            paper: "#ffffff",
        },
        divider: "#e0e0e0",
        action: {
            active: "#252525",
            hover: "#f5f5f5",
            selected: "#eeeeee",
            disabled: "#bdbdbd",
            disabledBackground: "#e0e0e0",
            focus: "#e0e0e0",
            hoverOpacity: 0.08,
            selectedOpacity: 0.16,
            disabledOpacity: 0.38,
        },
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h3: {
            fontSize: "1.75rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: 600,
            letterSpacing: -0.7
        },

    }
})