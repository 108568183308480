// BulletPointList.tsx
import React from 'react';
import { List, ListItem, Typography, SxProps, Box } from '@mui/material';
import { paragraphToBulletPoints } from "../hooks/useBulletPoints";

interface BulletPointListProps {
    paragraph: string;
    listStyle?: 'default' | 'bullet';
}

const BulletPointList: React.FC<BulletPointListProps> = ({ paragraph, listStyle = 'default' }) => {
    const bulletPoints = paragraphToBulletPoints(paragraph);

    // Conditionally apply styles based on listStyle prop
    const listSx: SxProps = listStyle === 'bullet' ? { listStyleType: 'disc', ml: '16px' } : {};

    return (
        <>
            {bulletPoints?.length ? (
                <List sx={listSx}>
                    {bulletPoints?.map((point, index) => (
                        <ListItem key={index} sx={{ display: 'list-item', mb: "8px" }} disablePadding>
                            <Typography variant="body2">
                                {point}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Box sx={{width: 400, height: 150, display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    No data to display
                </Box>
            )}</>

    );
};
export default BulletPointList;