// routesconfig.ts
export interface RouteConfig {
    path?: string;
    breadcrumb: string;
    children?: { [key: string]: RouteConfig };
    disabled?: boolean;
    isContainer?: boolean;
    isDynamic?: boolean;
}

export interface BreadcrumbRouteConfig extends RouteConfig {
    breadcrumbs?: { path: string; breadcrumb: string }[];
}

export const AppRoutes: { [key: string]: RouteConfig } = {
    home: {
        path: "/",
        breadcrumb: "Home",
    },
    crisisManagement: {
        breadcrumb: "Crisis Management",
        isContainer: true,
        children: {
            tracker: {
                path: "/submissions/tracker",
                breadcrumb: "Submissions Tracker",
            },
            details: {
                path: "/submissions/tracker/:id",
                breadcrumb: "Submission Details",
                isDynamic: true,
            },
        },
    },
    underwritingWorkbench: {
        isContainer: true,
        breadcrumb: "Underwriting Workbench",
        children: {
            newSubmission: {
                path: "/",
                breadcrumb: "New Workbench",
            },
        },
    },
    demoTools: {
        isContainer: true,
        breadcrumb: "Demo Tools",
        children: {
            newSubmission: {
                path: "/demo-tools/new-submission",
                breadcrumb: "New Submission",
            },
        },
    },
    howItWorks: {
        path: "/how-it-works",
        breadcrumb: "How It Works",
    },
};

export function getRoutePath(route: string): string {
    const keys = route.split('.');
    let current: any = AppRoutes;
    for (const key of keys) {
        if (current[key]) {
            current = current[key];
        } else {
            throw new Error(`Route not found: ${route}`);
        }
    }
    return current.path || '';
}