import { useState } from "react";

interface NotificationState {
  open: boolean;
  message: string;
  severity: "info" | "success" | "warning" | "error";
}

const useNotification = () => {
  const [notification, setNotification] = useState<NotificationState>({
    open: false,
    message: "",
    severity: "info"
  });

  const showNotification = (message: string, severity: "info" | "success" | "warning" | "error") => {
    setNotification({ open: true, message, severity });
  };

  const closeNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return { notification, showNotification, closeNotification };
};

export default useNotification;