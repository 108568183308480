import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Chip,
  Divider,
  Button,
  Accordion,
  AccordionSummary,
  Stack,
  AccordionDetails,
} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AgeChip } from "../../../components";
import { getStatusColor, getFlagColor } from "../constants";
import BulletPointList from "../../../components/bulletpoints";
import HeadingWithIcon from "../../../components/HeadingWithIcon";
import SubmissionDetailsCard from "./SubmissionDetailsCard";
import PrioritySlider from '../components/PrioritySlider';
import ManageSubmission from "../../tracker/components/ManageSubmission";
import HorizontalStackedBarChart from "../Exposure/StackedBar";



interface Props {
  complaint: any;
}
// hardcoded for demo and testing. should come from complaints object
const data = [
  { label: 'Location', value: 5 },
  { label: 'Occupancy', value: 5 },
  { label: 'Coverage', value: 10 },
  { label: 'Country', value: 10 },
];

export const CaseDetails = ({ complaint }: Props) => {

  const updatePriority = (newPriority: number) => {
    console.log('Updated priority to:', newPriority);
    // Implement the logic to update the priority, e.g., an API call
  };


  return (
    <Grid container spacing={3} mt={0} justifyContent="space-between">
      <Grid item xs={12} md={12}>
        <Card
          variant="outlined"
          style={{
            padding: '8px',
            // minHeight: '300px'
          }}>
          <CardContent>


            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 1, md: 3 }} pb={2} justifyContent={"space-between"}>
              <HeadingWithIcon
                icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
                variant="h6"
                fontWeight="bold">
                Prioritisation
              </HeadingWithIcon>

              <ManageSubmission updatePriority={updatePriority} showPrioritySlider={false} />
            </Stack>

            <Divider
              sx={{
                mb: 2
              }} />

            <PrioritySlider complaint={complaint} updatePriority={updatePriority} showDialog={true} />

          </CardContent>
        </Card>

      </Grid>

      {/* submission details main sections */}
      <Grid item xs={12} md={6}>
        <SubmissionDetailsCard complaint={complaint} />

        <Card
          variant="outlined"

          style={{
            padding: '8px',
            minHeight: '300px',
            marginBottom: '24px',
            marginTop: '24px'
          }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Attached email body
            </Typography>
            <Divider
              sx={{
                mb: 2
              }} />
            <BulletPointList
              paragraph={complaint.submission_summary && complaint.submission_summary.Summary
                ? complaint.submission_raw
                : 'TBA'}
              listStyle="default" />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6} mt={0} >

        <Card
          variant="outlined"
          style={{
            padding: '8px',
            marginBottom: '24px',
            // minHeight: '300px'
          }}>
          <CardContent>
            <HeadingWithIcon
              icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
              variant="h6"
              fontWeight="bold">
              Risk assessment
            </HeadingWithIcon>
            <Divider
              sx={{
                mb: 2
              }} />

            <Grid container spacing={2}>



              <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight="bold">Exposure:</Typography>
              </Grid>
              <Grid item xs={12}>
                <Box width="100%">
                  <HorizontalStackedBarChart data={data}
                    current={25}
                    limit={40} />
                </Box>

              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">Red Flags:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{complaint?.submission_basic_checks?.RedFlags}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">Broker Priority:</Typography>
                <Typography variant="caption">(data from iHub)</Typography>
              </Grid>
              <Grid item xs={8}>

                <Chip size="small" label={complaint?.submission_basic_checks?.BrokerPriority ?? "N/A"} color={getFlagColor(complaint?.submission_basic_checks?.BrokerPriority)} sx={{ margin: "4px" }} />
                <Typography variant="caption" fontWeight="bold">
                  Tier One Broker - GBP £10,000,000
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">Risk Management Score:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Chip size="small" label={complaint?.submission_basic_checks?.RiskManagementScore ?? "N/A"} color={getFlagColor(complaint?.submission_basic_checks?.RiskManagementScore)} />

              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="summary-content"
                    id="summary-header"
                  >
                    <HeadingWithIcon icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />} variant="subtitle2" fontWeight="bold">
                      Underwriting Guidelines Assessment
                    </HeadingWithIcon>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BulletPointList
                      paragraph={complaint.submission_basic_checks && complaint.submission_basic_checks.Summary
                        ? complaint.submission_basic_checks.Summary
                        : 'TBA'}
                      listStyle="default"
                    />
                  </AccordionDetails>

                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="summary-content"
                    id="summary-header"
                  >
                    <HeadingWithIcon icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />} variant="subtitle2" fontWeight="bold">
                      Crisis24 Summary
                    </HeadingWithIcon>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <BulletPointList
                      paragraph={complaint.submission_basic_checks && complaint.submission_riskassessment.Summary
                        ? complaint.submission_riskassessment.Summary
                        : 'TBA'}
                      listStyle="default"
                    /> */}
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                      {complaint.submission_basic_checks && complaint.submission_riskassessment.Summary
                        ? complaint.submission_riskassessment.Summary
                        : 'TBA'}
                    </Typography>
                  </AccordionDetails>

                </Accordion>

                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="summary-content"
                    id="summary-header"
                  >
                    <Typography variant="subtitle2" fontWeight="bold">Crisis24 Full Country Report</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <BulletPointList
                      paragraph={complaint.submission_riskassessment && complaint.submission_riskassessment.CountryReport
                        ? complaint.submission_riskassessment.CountryReport
                        : 'TBA'}
                      listStyle="default"
                    /> */}
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                      {complaint.submission_riskassessment && complaint.submission_riskassessment.CountryReport
                        ? complaint.submission_riskassessment.CountryReport
                        : 'TBA'}
                    </Typography>
                  </AccordionDetails>

                </Accordion>

              </Grid>
            </Grid>

          </CardContent>
        </Card>

        <Card
          variant="outlined"
          style={{
            padding: '8px',
            minHeight: '300px',
            marginBottom: '24px'
          }}>
          <CardContent>
            <HeadingWithIcon
              icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
              variant="h6"
              fontWeight="bold">
              Summary
            </HeadingWithIcon>
            <Divider
              sx={{
                mb: 2
              }} />
            {/* <BulletPointList
              paragraph={complaint.submission_summary && complaint.submission_summary.Summary
                ? complaint.submission_summary.Summary
                : 'TBA'}
              listStyle="bullet" /> */}
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {complaint.submission_summary && complaint.submission_summary.Summary
                ? complaint.submission_summary.Summary
                : 'TBA'}

            </Typography>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  );
};