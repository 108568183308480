export const DaysBetweenDates = (_date1: string, _date2: string) => {
  if (!_date1 || !_date2) {
    return;
  }

  // Create Date objects representing the two dates
  const date1ExcludingTime = new Date(_date1).toDateString(); // we need to remove the time, otherwise if its less than 24 hours it still treat same day
  const date1: Date = new Date(date1ExcludingTime);
  const date2: Date = new Date(_date2);

  // Calculate the difference in
  // milliseconds between the two dates
  const differenceInMs: number = Math.abs(date2.getTime() - date1.getTime());

  // Define the number of milliseconds in a day
  const millisecondsInDay: number = 1000 * 60 * 60 * 24;

  // Calculate the difference in days by
  // dividing the difference in milliseconds by
  // milliseconds in a day
  const differenceInDays: number = Math.floor(
    differenceInMs / millisecondsInDay
  );

  return differenceInDays;
};
