// sidebarConfig.ts
import { AppRoutes, RouteConfig } from './routesConfig';

export type IconName = 'Home' | 'PendingActions' | 'Segment' | 'Settings' | 'DisplaySettings';

export interface SubItem {
  name: string;
  href: string;
  disabled?: boolean;
}

export interface SidebarItem {
  title: string;
  icon: IconName;
  link?: string;
  collapsible?: boolean;
  defaultOpen?: boolean;  // New property
  items?: SubItem[];
}

function getSubItems(routes: { [key: string]: RouteConfig }): SubItem[] {
  return Object.entries(routes).map(([key, route]) => ({
    name: route.breadcrumb,
    href: route.path || '',
    disabled: route.disabled
  }));
}

export const sidebarConfig: SidebarItem[] = [
  {
    title: AppRoutes.home.breadcrumb,
    icon: "Home",
    link: AppRoutes.home.path,
  },
  {
    title: AppRoutes.crisisManagement.breadcrumb,
    icon: "PendingActions",
    collapsible: true,
    defaultOpen: true,  // Set to be open by default
    items: [
      { name: "Submission Tracker", href: AppRoutes.crisisManagement.children?.tracker.path || "" },
      { name: "ML Insights", href: "#", disabled: true },
    ],
  },
  {
    title: AppRoutes.underwritingWorkbench.breadcrumb,
    icon: "DisplaySettings",
    collapsible: true,
    defaultOpen: true,  // Set Demo Tools to be closed by default
    items: getSubItems(AppRoutes.underwritingWorkbench.children || {}),
  },
  {
    title: AppRoutes.demoTools.breadcrumb,
    icon: "Segment",
    collapsible: true,
    defaultOpen: true,  // Set Demo Tools to be closed by default
    items: getSubItems(AppRoutes.demoTools.children || {}),
  },
  {
    title: "Settings & Help",
    icon: "Settings",
    collapsible: true,
    defaultOpen: false,  // Set Settings & Help to be closed by default
    items: [
      { name: "Settings (coming soon)", href: "#settings", disabled: true },
      { name: "Help (coming soon)", href: "#help", disabled: true },
    ],
  },
];