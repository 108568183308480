import { Snackbar, Alert } from '@mui/material';
import React from 'react';

interface NotificationProps {
  open: boolean;
  message: string;
  severity: "success" | "error" | "warning" | "info";
  onClose: () => void; // Add onClose prop to handle closing the Snackbar
}

const Notification: React.FC<NotificationProps> = ({ open, message, severity, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={onClose} // Attach onClose handler
    >
      <Alert
        severity={severity}
        variant="filled"
        sx={{ width: '100%' }}
        onClose={onClose} // Ensure Alert can also handle the close event
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;