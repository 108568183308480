import "@aws-amplify/ui-react/styles.css";
import React from "react";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
// import config from "./amplifyconfiguration.json";
// import awsExports from "./aws-exports";

export const COGNITO_USER_POOL = process.env.REACT_APP_COGNITO_USER_POOL ?? "";
export const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID ?? "";

// Amplify.configure(config);
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: COGNITO_CLIENT_ID,
      userPoolId: COGNITO_USER_POOL,
      loginWith: { // Optional
        username: true,
        email: true, // Optional
        phone: false, // Optional
      }
    }
  }
});
// Amplify.configure(awsExports);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
