import { AppRoutes } from "../../../config/routesConfig";
import { SvgIconComponent } from "@mui/icons-material";
import PostAddSharpIcon from '@mui/icons-material/PostAddSharp';
import PreviewSharpIcon from '@mui/icons-material/PreviewSharp';



export interface Option {
  title: string;
  description: string;
  buttonLabel: string;
  navPath?: string;
  icon?: SvgIconComponent;
}

export const Options: Option[] = [
  {
    title: "Crisis Management",
    description:
      "Use this tool to get central view of all crisis management submissions.",
    buttonLabel: "View submissions",
    navPath: AppRoutes.crisisManagement.children?.tracker.path,
    icon: PreviewSharpIcon
  },
  {
    title: "New submission",
    description:
      "Use this tool to process a new submission.",
    buttonLabel: "New submission",
    navPath: AppRoutes.demoTools.children?.newSubmission.path,
    icon: PostAddSharpIcon
  },
  
];
