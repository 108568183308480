import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridPaginationModel } from "@mui/x-data-grid";

// Define the File interface for TypeScript
interface File {
  lineItemId: string;
  subId: string;
  country: string;
  long: string;
  lat: string;
  insurValue: string;
  limitVal: string;
  excessValue: string;
  covCode: string;
  occupancy: string;
}

// Define columns for DataGrid
const columns: GridColDef[] = [
  { field: "lineItemId", headerName: "Line Item ID", width: 150 },
  { field: "subId", headerName: "Submission ID", width: 150 },
  { field: "country", headerName: "Country", width: 150 },
  { field: "long", headerName: "Longitude", width: 150 },
  { field: "lat", headerName: "Latitude", width: 150 },
  { field: "insurValue", headerName: "Insurance Value", width: 150 },
  { field: "limitVal", headerName: "Limit Value", width: 150 },
  { field: "excessValue", headerName: "Excess Value", width: 150 },
  { field: "covCode", headerName: "Coverage Code", width: 150 },
  { field: "occupancy", headerName: "Occupancy", width: 150 },
];

// AttachedFiles component
export const Locations: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);
  const [pageSize, setPageSize] = useState<number>(20);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    fetch("/dummy_locations.csv")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.text();
      })
      .then((data) => {
        console.log("Fetched CSV data:", data); // Log fetched data
        const parsedFiles = parseCSV(data);
        setFiles(parsedFiles);
      })
      .catch((error) => console.error("Error fetching the file:", error));
  }, []);

  const parseCSV = (data: string): File[] => {
    const lines = data.split("\n");
    const headers = lines[0].split("|");
    console.log("CSV Headers:", headers); // Log headers

    return lines
      .slice(1)
      .map((line) => {
        const values = line.split("|");
        const file: File = {
          lineItemId: values[0],
          subId: values[1],
          country: values[2],
          long: values[3],
          lat: values[4],
          insurValue: values[5],
          limitVal: values[6],
          excessValue: values[7],
          covCode: values[8],
          occupancy: values[9],
        };
        return file;
      })
      .filter((file) => file.lineItemId);
  };

  return (
    <Box sx={{ height: "75%", width: "100%", padding: 3 }}>
      <DataGrid
        rows={files}
        columns={columns}
        paginationModel={{ pageSize, page }}
        onPaginationModelChange={(model: GridPaginationModel) => {
          setPageSize(model.pageSize);
          setPage(model.page);
        }}
        pageSizeOptions={[20, 40, 60]}
        pagination
        getRowId={(row) => row.lineItemId}
      />
    </Box>
  );
};

export default Locations;
