// Breadcrumbs.tsx
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { AppRoutes, RouteConfig } from '../../config/routesConfig';

interface LinkRouterProps {
  to: string;
  replace?: boolean;
  children: React.ReactNode;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link underline="hover" {...props} component={RouterLink as any} />;
}

interface FlattenedRoute {
  path: string;
  breadcrumbs: string[];
  isContainer: boolean;
  isDynamic: boolean;
}

const flattenedRoutes: FlattenedRoute[] = [];

function flattenRouteConfig(config: { [key: string]: RouteConfig }, parentPath: string = '', parentBreadcrumbs: string[] = []) {
  Object.entries(config).forEach(([key, route]) => {
    const currentPath = route.path ? (route.path.startsWith('/') ? route.path : `${parentPath}/${route.path}`) : parentPath;
    const currentBreadcrumbs = [...parentBreadcrumbs, route.breadcrumb];

    flattenedRoutes.push({
      path: currentPath,
      breadcrumbs: currentBreadcrumbs,
      isContainer: !!route.isContainer,
      isDynamic: !!route.isDynamic,
    });

    if (route.children) {
      flattenRouteConfig(route.children, currentPath, currentBreadcrumbs);
    }
  });
}

flattenRouteConfig(AppRoutes);

function findBreadcrumbConfig(path: string): FlattenedRoute | undefined {
  // First, try to find an exact match
  let matchingRoute = flattenedRoutes.find(route => route.path === path);

  // If no exact match is found, look for a dynamic route match
  if (!matchingRoute) {
    const pathParts = path.split('/');
    matchingRoute = flattenedRoutes.find(route => {
      if (!route.isDynamic) return false;
      const routeParts = route.path.split('/');
      return routeParts.length === pathParts.length && routeParts.every((part, index) => 
        part.startsWith(':') || part === pathParts[index]
      );
    });
  }

  return matchingRoute;
}

export function AppBreadcrumbs() {
  const location = useLocation();
  const matchingRoute = findBreadcrumbConfig(location.pathname);

  if (!matchingRoute || location.pathname === '/') {
    return null;
  }

  return (
    <Box mb={2}>
      <Breadcrumbs aria-label="breadcrumb">
        {matchingRoute.breadcrumbs.map((crumb, index) => {
          const isLast = index === matchingRoute.breadcrumbs.length - 1;
          const isContainer = index < matchingRoute.breadcrumbs.length - 1 && 
            flattenedRoutes.find(route => route.breadcrumbs[index] === crumb)?.isContainer;

          if (isContainer || isLast) {
            return (
              <Typography color="text.primary" key={index}>
                {crumb}
              </Typography>
            );
          } else {
            const to = '/' + matchingRoute.breadcrumbs.slice(0, index + 1).join('/').toLowerCase();
            return (
              <LinkRouter to={to} key={index}>
                {crumb}
              </LinkRouter>
            );
          }
        })}
      </Breadcrumbs>
    </Box>
  );
}

// For debugging
// console.log('Flattened Routes:', flattenedRoutes);