import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import BulletPointList from "../../../components/bulletpoints";
import HeadingWithIcon from "../../../components/HeadingWithIcon";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

interface SummaryDialogProps {
    onClose: (value: string) => void;
    selectedValue: string;
    open: boolean;
}

export function SummaryDialog(props: SummaryDialogProps) {
    const { onClose, selectedValue, open } = props;
    const [summaryText, setSummaryText] = useState('');

    const handleClose = () => {
        onClose(selectedValue);
    };

    useEffect(() => {
        if (open) {
            try {
                const parsedValue = (selectedValue);
                // const parsedValue = JSON.parse(selectedValue);
                setSummaryText(parsedValue);
                // console.log("parsed value", parsedValue);
            } catch (error) {
                console.error('Failed to parse JSON:', error);
                setSummaryText('Invalid summary format');
            }
        }
    }, [open, selectedValue]);

    return (
        <Dialog onClose={handleClose} open={open}>
            <Box py={2} mt={1} ml={3}>
                
            <HeadingWithIcon
              icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
              variant="h6"
              fontWeight="bold">
              Submission Summary
            </HeadingWithIcon>

            </Box>
            <DialogContent dividers>
                <BulletPointList paragraph={summaryText} listStyle="bullet" />
            </DialogContent>
        </Dialog>
    );
}