import { fetchAuthSession } from "aws-amplify/auth";
import { NewComplaintDataRequest } from "../interfaces";

export const BASE_CMS_API_URL = process.env.REACT_APP_CMS_API_BASE_URL;
export const BASE_ENGINE_API_URL = process.env.REACT_APP_ENGINE_API_BASE_URL;

// Fetch Customer Names
export async function fetchAllCustomers() {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);
    const response = await fetch(`${BASE_CMS_API_URL}/customer/`, {
      headers: {
        Authorization: `Bearer ${identityToken}`,
      },
    });
    if (!response.ok) {
      throw new Error("Not 2xx response", { cause: response });
    } else {
      const data = await response.json();
      return data;
    }
  } catch (e: any) {
    console.log("GET call failed: ", e);
    return null;
  }
}

// Fetch Customer Details
export async function fetchCustomer(customerId: string) {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);
    const response = await fetch(`${BASE_CMS_API_URL}/customer/${customerId}`, {
      headers: {
        Authorization: `Bearer ${identityToken}`,
      },
    });
    if (!response.ok) {
      throw new Error("Not 2xx response", { cause: response });
    } else {
      const data = await response.json();
      return data;
    }
  } catch (e: any) {
    console.log("GET call failed: ", e);
    return null;
  }
}

// Fetch all Complaints
export async function fetchComplaintList() {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    const response = await fetch(`${BASE_CMS_API_URL}/records`, {
      headers: {
        Authorization: `Bearer ${identityToken}`,
      },
    });
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

// Fetch single Complaint by id
export async function fetchSingleComplaint(complaintId: string) {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);
    const response = await fetch(
      `${BASE_CMS_API_URL}/records/${complaintId}`,
      {
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
      }
    );
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}

// create new complaint
export async function createNewComplaint(
  newComplaint: NewComplaintDataRequest
) {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    const response = await fetch(`${BASE_CMS_API_URL}/records`, {
      headers: {
        Authorization: `Bearer ${identityToken}`,
      },
      method: "POST",
      body: JSON.stringify(newComplaint),
    });
    const data = await response.json();
    return data;
  } catch (e: any) {
    console.log("GET call failed: ", e);
  }
}

// update a complaint
export async function updateComplaint(complaintId: string, complaintData: any) {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    const response = await fetch(
      `${BASE_CMS_API_URL}/records/${complaintId}`,
      {
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
        method: "POST",
        body: JSON.stringify(complaintData),
      }
    );
    const data = await response.json();
    return data;
  } catch (e: any) {
    console.log("GET call failed: ", e);
  }
}

// Fetch attached audio file from S3
export async function fetchAudioFile(complaint_id: string) {
  try {
    console.log("Fetching Auth Session");
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    const requestBody = {
      complaint_id,
      "type": "get_audiorecording"
    };

    const response = await fetch(

      `${BASE_ENGINE_API_URL}/presign`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
        body: JSON.stringify(requestBody)
      }
    );
    const data = await response.json();
    return data;

  } catch (e: any) {
    console.log("POST call to fetch audio file failed: ", e);
  }
}

// Complaint Generation API

export const downloadFileFromS3 = async (prompt: string): Promise<string | null> => {
  try {
    console.log("Fetching Auth Session");
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    const requestBody = {
      prompt
    };

    const response = await fetch(`${BASE_ENGINE_API_URL}/demo`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${identityToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    });

    if (!response.ok) {
      throw new Error(`Failed to get presigned URL. Status: ${response.status}`);
    }

    const postResponseUrl = await response.json();

    const downloadResponse = await fetch(postResponseUrl, {
      method: "GET"
    });

    if (!downloadResponse.ok) {
      const errorText = await downloadResponse.text();
      throw new Error(`Failed to download file. Status: ${downloadResponse.status}, Response: ${errorText}`);
    }

    const blob = await downloadResponse.blob();
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'demo_complaint.mp3'; // Default filename, change as necessary
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    return "File downloaded successfully!";
  } catch (error) {
    return null;
  }
};


// Add notes to a complaint
export async function addNotesToComplaint(payload: any) {
  try {
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    const response = await fetch(
      `${BASE_ENGINE_API_URL}/update`,
      {
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
        method: "POST",
        body: JSON.stringify(payload),
      }
    );

    const data = await response.json();
    return data;
  } catch (e: any) {
    console.log("addNotesToComplaint call failed: ", e);
  }
};


// Fetch files attached for complaint
export async function fetchAttachments(complaint_id: string) {
  try {
    console.log("Fetching Auth Session");
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    const requestBody = {
      complaint_id
    };

    const response = await fetch(

      `${BASE_ENGINE_API_URL}/filelist`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
        body: JSON.stringify(requestBody)
      }
    );
    const data = await response.json();
    return data;

  } catch (e: any) {
    console.log("GET call to fetch attachments failed: ", e);
  }
}

// Request reprocess for a complaint
export async function requestReprocess(complaint_id: string) {
  try {
    console.log("Fetching Auth Session");
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    const requestBody = {
      complaint_id
    };

    const response = await fetch(

      `${BASE_ENGINE_API_URL}/reprocess`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
        body: JSON.stringify(requestBody)
      }
    );
    const data = await response.json();
    return data;

  } catch (e: any) {
    console.log("POST call to reprocess case failed: ", e);
  }
}



// Request reprocess for a complaint
export async function purgeComplaints() {
  try {
    console.log("Fetching Auth Session");
    const cognitoTokens = (await fetchAuthSession()).tokens;
    const identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    const requestBody = {
      
    };

    const response = await fetch(

      `${BASE_ENGINE_API_URL}/cleanup`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${identityToken}`,
        },
        body: JSON.stringify(requestBody)
      }
    );
    const data = await response.json();
    return data;

  } catch (e: any) {
    console.log("POST call to purge case failed: ", e);
  }
}