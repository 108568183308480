import { useState, useEffect } from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    Divider,
    IconButton
    , Button, Stack

} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AddCircleIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/Remove';
import HeadingWithIcon from "../../../components/HeadingWithIcon";



const Scorecard = ({ complaint }: any) => {

    return (
        <Grid container spacing={3} mt={0} justifyContent="space-between">

            <>

                <Grid item xs={12} md={12}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '200px',
                            // backgroundColor: theme.palette.primary.light
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Quote
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} />



                            <Grid container spacing={2}>


                                {/* <ExposureGauges /> */}

                                <Grid item xs={12}>

                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Quote Price
                                    </Typography>
                                    {/* <IconButton aria-label="delete">
                                        <AddCircleIcon fontSize="large" color="primary" />
                                    </IconButton>
                                    <IconButton aria-label="delete">
                                   
                                        <RemoveCircleIcon fontSize="large" color="primary"/>
                                    </IconButton> */}
                                    <Stack direction="row" spacing={2}>
                                        <Typography variant="h1" fontSize={64} component="div">
                                            £ 30,000
                                        </Typography>
                                        <>
                                        <IconButton aria-label="delete">
                                        <AddCircleIcon fontSize="large" color="primary" />
                                    </IconButton>
                                    <IconButton aria-label="delete">
                                   
                                        <RemoveCircleIcon fontSize="large" color="primary"/>
                                    </IconButton>
                                    </>
                                    </Stack>
                                    {/* <Typography variant="h1" fontSize={64} component="div">
                                        £ 30,000
                                    </Typography> */}
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        - 29%
                                    </Typography>
                                    {/* <Typography variant="body2">
                                        <AutoAwesomeIcon color="primary" fontSize="inherit" />
                                        Based on similar deals and the context of this quote, we recommend reducing the price by 2.5%.
                                    </Typography> */}
                                    <br />
                                    <Divider />
                                    <br />




                                </Grid>



                                <Grid item xs={3}>

                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        AI Price
                                    </Typography>
                                    <Typography variant="h1" component="div">
                                        £ 35,000
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        - 24%
                                    </Typography>
                                    <Typography variant="body2">
                                        <AutoAwesomeIcon color="primary" fontSize="inherit" />
                                        Based on similar deals and the context of this quote, we recommend reducing the price by 2.5%.
                                    </Typography>
                                    <br />
                                    <Divider />
                                    <br />

                                    <Button > Save Quote</Button>


                                </Grid>

                                <Grid item xs={3}>

                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        Technical Price
                                    </Typography>
                                    <Typography variant="h1" component="div">
                                        £ 42,000
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">

                                    </Typography>
                                    <Typography variant="body2">
                                        Generated from pricing Excel 6
                                    </Typography>

                                </Grid>



                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>





                <Grid item xs={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '200px'
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                A similar deal
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} />
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Liability
                            </Typography>
                            <Typography variant="h4" component="div">
                                £ 125,650,000
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                subtext goes here
                            </Typography>
                            <Typography variant="body2">
                                Some text about price here.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '200px'
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                A recent deal
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} />
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Liability
                            </Typography>
                            <Typography variant="h4" component="div">
                                £ 125,650,000
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                subtext goes here
                            </Typography>
                            <Typography variant="body2">
                                Some text about price here.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '200px'
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                A great deal
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} />
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Liability
                            </Typography>
                            <Typography variant="h4" component="div">
                                £ 125,650,000
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                subtext goes here
                            </Typography>
                            <Typography variant="body2">
                                Some text about price here.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>


            </>

        </Grid>
    );
};

export default Scorecard;
