import { useEffect, useState } from "react";
import { Box, Card, Typography, Button, Chip, Tooltip, useTheme } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { fetchComplaintList } from "../../api";
import { getFlagColor } from "../submission-details/constants";
import "./Tracker.css";
import { SummaryDialog } from "./components";
import { AgeChip } from "../../components";
import { styled } from '@mui/material/styles';
import { red } from "@mui/material/colors";
import ManageSubmission from "./components/ManageSubmission";
import { AppRoutes } from "../../config/routesConfig";

function getRowId(row: any) {
  return row.record_id;
}

const ComplaintsTracker = () => {
  const theme = useTheme();
  
  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Red': {
      // border: `2px solid ${theme.palette.error.main} !important`,
      border: "none"
    },
  }));


  const navigate = useNavigate();
  const [complaintsList, setComplaintsList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastRefreshed, setLastRefreshed] = useState<Date | null>(null);

  const [open, setOpen] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState("");

  const handleOpenDialog = (summary: string) => {
    setSelectedSummary(summary);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchComplaintsData();
  }, []);

  useEffect(() => {
    if (complaintsList?.length) {
      setTableData(complaintsList);
    }
  }, [complaintsList]);

  const fetchComplaintsData = async () => {
    setLoading(true);
    const response = await fetchComplaintList();
    // console.log("complaints list", response); // for debugging
    if (response) {
      setComplaintsList(response);
      setLastRefreshed(new Date());
    }
    setLoading(false);
  };

  const handleRefetch = () => {
    fetchComplaintsData();
  };

  const formatLastRefreshed = (date: Date): string => {
    const now = new Date();

    const isSameDay = now.toDateString() === date.toDateString();
    const isYesterday =
      new Date(now.getTime() - 24 * 60 * 60 * 1000).toDateString() ===
      date.toDateString();

    if (isSameDay) {
      return `today at ${date.toLocaleTimeString()}`;
    } else if (isYesterday) {
      return `yesterday at ${date.toLocaleTimeString()}`;
    } else {
      const dayOfWeek = date.toLocaleDateString(undefined, { weekday: "long" });
      return `${dayOfWeek} at ${date.toLocaleTimeString()}`;
    }
  };

  const updatePriority = (newPriority: number) => {
    console.log('Updated priority to:', newPriority);
    // Implement the logic to update the priority, e.g., an API call
  };


  const columns: GridColDef[] = [
    {
      field: "record_id",
      headerName: "Submission ID",
      flex: 0.5,
      minWidth: 120,
    },
    {
      field: "submission_broker",
      headerName: "Insured Company",
      flex: 0.9,
      renderCell: (params) => params.row.submission_broker?.InsuredCompany ?? "N/A",
      
    },
    {
      field: "submission_totalvalue",
      headerName: "Total Value",
      flex: 0.9,
      renderCell: (params) => {
        const value = params.row.submission_totalvalue?.Value;
        const currencyCode = params.row.submission_totalvalue?.CurrencyCode;
        const formattedValue = value ? new Intl.NumberFormat().format(value) : "N/A";
        return `${formattedValue} ${currencyCode}`;
      }
    },
    {
      field: "submission_occupancy",
      headerName: "Occupancy",
      flex: 0.9,
      renderCell: (params) => params.row.submission_occupancy?.Country ?? "N/A",
    },
    {
      field: "date_created",
      headerName: "Received",
      flex: 0.8,
      renderCell: (params) => (
        <AgeChip dateCreated={params?.row?.date_created} />
      ),
    },
    {
      field: "record_status",
      headerName: "Status",
      flex: 0.7,
      renderCell: (params) => (
        <Chip size="small" label={params.row.record_status ?? "N/A"} color={getFlagColor(params.row.record_status)} />
      ),
    },
    
    {
      field: "submission_basic_checks",
      headerName: "Flags",
      flex: 0.6,
      renderCell: (params) => (
        <Tooltip title={params.row.submission_basic_checks.RedFlags} arrow>
        <Chip size="small" label={params.row.submission_basic_checks.Status ?? "N/A"} color={getFlagColor(params.row.submission_basic_checks.Status)}  />
        </Tooltip>
      ),
    },
    {
      field: "submission_priority",
      headerName: "Priority",
      flex: 0.6,
      renderCell: (params) => {
        const priority = Math.floor(Math.random() * 10) + 1; // Generate random number between 1 and 10
        return (
   
            <Chip size="small" label={priority} color="default" />

        );
      },
    },
    // {
    //   field: "submission_priority",
    //   headerName: "Priority",
    //   flex: 0.6,
    //   renderCell: (params) => (
    //     <Tooltip title={params.row.submission_basic_checks.RedFlags} arrow>
    //     <Chip size="small" label={params.row.submission_basic_checks.Status ?? "N/A"} color={getFlagColor(params.row.submission_basic_checks.Status)}  />
    //     </Tooltip>
    //   ),
    // },
    
    {
      field: "action",
      headerName: "Actions",
      flex: 1.4,
      minWidth: 350,
      renderCell: (params) => (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          gap={2}
        >
          <Button
            onClick={() =>
              navigate(`${AppRoutes.crisisManagement.children?.tracker.path}/${params.row.record_id}`)
            }
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
            }}
          >
            Details
          </Button>

          <Button
            onClick={() => handleOpenDialog(params.row.submission_summary.Summary)}
            variant="outlined"
            size="small"
            style={{
              textTransform: "none",
            }}
          >
            Summary
          </Button>

          <ManageSubmission updatePriority={updatePriority} showPrioritySlider={false} />

        </Box>
      ),
    },
  ];

  return (
    <div>
      <Typography variant="h1" marginBottom={"12px"} gutterBottom>
        Submission Tracker
      </Typography>
      <Box display="flex" alignItems="center">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleRefetch}
          disabled={loading}
        >
          {loading ? "Loading..." : "Refresh data"}
        </Button>
        {lastRefreshed && (
          <Typography variant="caption" style={{ marginLeft: "10px" }}>
            Last refreshed {formatLastRefreshed(lastRefreshed)}
          </Typography>
        )}
      </Box>
      <Card
        variant="outlined"
        style={{
          borderRadius: "8px",
          marginTop: "16px",
        }}
        className="complaintstable"
      >
        <StyledDataGrid
          loading={loading}
          disableRowSelectionOnClick
          getRowId={getRowId}
          rows={tableData}
          columns={columns}
          getRowClassName={(params) => `super-app-theme--${params.row.submission_basic_checks.Status}`}
          initialState={{
            sorting: { sortModel: [{ field: "date_created", sort: "desc" }] },
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: {
                disableToolbarButton: true,
              },
              csvOptions: {
                disableToolbarButton: true,
              },
            },
            columnHeaders: {
              style: {
                outline: "none",
              },
            },
            cell: {
              style: {
                outline: "none",
              },
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          sx={{
            boxShadow: 0,
            border: 0,
          }}
        />
      </Card>

      <SummaryDialog
        open={open}
        selectedValue={selectedSummary}
        onClose={handleCloseDialog}
      />




    </div>
  );
};

export default ComplaintsTracker;
