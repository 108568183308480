import React from 'react';
import { Typography, TypographyProps } from "@mui/material";
interface SubheadingWithIconProps extends TypographyProps {
  icon?: React.ReactNode;
  children: React.ReactNode;
}

const HeadingWithIcon: React.FC<SubheadingWithIconProps> = ({ icon, children, variant, fontWeight, ...typographyProps }) => {
  return (
    <Typography 
      variant={variant} 
      fontWeight={fontWeight} 
      gutterBottom 
      display="flex" 
      flexDirection="row" 
      alignItems="center" 
      {...typographyProps}
    >
      {children}
      {icon && (
        <span style={{ marginLeft: '4px' }}>
          {icon}
        </span>
      )}
    </Typography>
  );
};

export default HeadingWithIcon;