import React, { useState } from 'react';
import { Alert, Box, Slider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface PrioritySliderProps {
  complaint: any;
  updatePriority: (newPriority: number) => void;
  showDialog: boolean;
}

const HiddenSlider = styled(Slider)({
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    background: 'linear-gradient(to right, red, yellow, green)',
    height: 4,
  },
  '& .MuiSlider-rail': {
    background: 'linear-gradient(to right, red, yellow, green)',
    height: 4,
  },
});

const PrioritySlider: React.FC<PrioritySliderProps> = ({ complaint, updatePriority, showDialog }) => {
  const [value, setValue] = useState<number>(complaint.priority_recommended || 7); // Use recommended min value for initial state
  const [MLvalue] = useState<number[]>([5, 8]); // Hardcoded ML values for testing

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const newPriority = newValue as number;
    setValue(newPriority);
    updatePriority(newPriority);
  };

  return (
    <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignContent={"center"} alignItems="center">
      {/* <KeyboardDoubleArrowDownIcon /> */}
      <Box px={2} sx={{ width: "100%" }}>
        <Slider
          aria-labelledby="priority-slider"
          value={value}
          track={false}
          onChange={handleSliderChange}
          step={1}
          min={1}
          max={10}
          valueLabelDisplay="on"
          sx={{
            '& .MuiSlider-rail': {
              height: "8px !important",
            },
            '& .MuiSlider-markLabel': {
              // fontSize: '0.75rem',
            },
          }}
        />
        <HiddenSlider
          aria-labelledby="priority-slider"
          value={MLvalue}
          step={1}
          marks={[
            { value: 1, label: 'Lowest' },
            { value: complaint.priority_recommended_min || 4, label: 'ML Min' },
            { value: complaint.priority_recommended_max || 8, label: 'ML Max' },
            { value: 100, label: 'Highest' }
          ]}
          min={1}
          max={10}
          valueLabelDisplay="off"
          disabled
        />
        <Alert severity="info" sx={{ mt: 2 }}>
        <Typography variant="caption" align="center" color="textSecondary">
        ML Insights: This submission is recommended as a High priority (70 Score). The prediction range is wide because risks similar to this have been treated with a wide variation in priority by underwriters.
          </Typography>
        </Alert>

        
      </Box>
      {/* <KeyboardDoubleArrowUpIcon /> */}
      
    </Stack>
  );
};

export default PrioritySlider;