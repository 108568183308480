import { Chip, Tooltip } from "@mui/material";
import { DaysBetweenDates } from "../../utils";
import { getAgeColour } from "../../pages/submission-details/constants";

interface Props {
  dateCreated: string;
}
export function AgeChip({ dateCreated }: Props) {
  const age = DaysBetweenDates(dateCreated, new Date().toDateString());
  let ageLabel = "";
  if (age && isNaN(age)) {
    ageLabel = "Invalid date";
  } else {
    switch (age) {
      case 0:
        ageLabel = "Today";
        break;
      case 1:
        ageLabel = "Yesterday";
        break;
      default:
        ageLabel = `${age} days ago`;
        break;
    }
  }

  return (
    <Tooltip title={`Created on: ${dateCreated}`} placement="bottom">
      <Chip label={ageLabel} size="small" color={getAgeColour(age)} />
    </Tooltip>
  );
}
