import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Tabs, Tab, Typography, Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { fetchSingleComplaint } from "../../api";
import { ComplaintDetailsTabs } from "./constants";
import {
  CaseDetails,
  CustomTabPanel,
  ExposureManagement,
  TechnicalPricing,
  RiskAssessment,
  Locations,
} from "./components";
import Scorecard from "./Scorecard/Scorecard";
import LoadingComponent from "../../components/loading";
// import CaseSpeedDial from "./components/CaseSpeedDial";
import Notification from "../../components/Notification";
import useNotification from "../../hooks/useNotification";
import { ComplaintStepper } from "./components/Stepper";

const ComplaintDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [complaint, setComplaint] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>("");
  const [value, setValue] = useState<any>(0);

  const { notification, showNotification, closeNotification } =
    useNotification();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    setLoading(true);
    // showNotification("Fetching data...", "info");
    try {
      if (!id) {
        return;
      }
      const response = await fetchSingleComplaint(id);
      // if (response.complaint_raw !== "N/A") {
      //   const transcriptRaw = JSON.parse(response.complaint_raw);
      //   setTranscript(transcriptRaw);
      // } else {
      //   setTranscript(response.complaint_raw);
      // }
      setComplaint(response);
      setLoading(false);
      // showNotification("Data fetched successfully!", "success");
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setError(error.message);
        showNotification(error.message, "error");
      } else {
        setError(
          "An error occurred while fetching the data. Please try again."
        );
        showNotification(
          "An error occurred while fetching the data. Please try again.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) return <LoadingComponent />;
  if (error) return <div>{error}</div>;

  // hardcoded status for testing
  //  const complaintStatus = 'processing';

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        mb={2}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h3">Submission ID: {id}</Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          startIcon={<RefreshIcon />}
          onClick={fetchData}
        >
          Refresh
        </Button>
      </Box>
      <Box py={2} mb={2}>
        <ComplaintStepper record_status={complaint.record_status} />
        {/* <ComplaintStepper complaint_status={complaintStatus} /> */}
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#F3F3F3", borderRadius: "8px" }}>
        <Tabs value={value} onChange={handleChange}>
          {ComplaintDetailsTabs.map((tab: any, index: number) => (
            <Tab
              sx={{ textTransform: "none" }}
              key={tab.value}
              label={tab.label}
            />
          ))}
        </Tabs>
      </Box>
      {complaint ? (
        <Box>
          <CustomTabPanel value={value} index={0}>
            <CaseDetails complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Locations />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <ExposureManagement complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <TechnicalPricing complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Scorecard complaint={complaint} />
          </CustomTabPanel>
        </Box>
      ) : (
        <>
          <p>No complaint found with ID: {id}</p>
        </>
      )}
      {/* <CaseSpeedDial complaint={complaint} showNotification={showNotification} onAddNotesSuccess={fetchData} /> */}
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={closeNotification}
      />
    </Box>
  );
};

export default ComplaintDetails;
