// import * as React from "react";
import {
    Box,
    Typography,
    Button,
    Link,
    Card,
    CardContent,
    Grid,
    Divider,
    styled
    
} from "@mui/material";
import {Options} from "./components/constant";
// import {fetchUserAttributes} from "aws-amplify/auth";
import { useTheme } from '@mui/material/styles';

const Home = () => {
    const theme = useTheme();
    const GradientBackground = styled(Box)(({ theme }) => ({
        background: `linear-gradient(130deg, ${theme.palette.pwc.main} 10%, #e0e0e0 100%)`,
        borderRadius: "0.4em",
      }));

    // const [welcomeMessage,
    //     setWelcomeMessage] = React.useState("");

    // React.useEffect(() => {
    //     const getCurrentUsername = async() => {
    //         try {
    //             const attributes = await fetchUserAttributes();
    //             const first_name = attributes.given_name
    //             const last_name = attributes.family_name
    //             setWelcomeMessage(`Welcome, ${first_name} ${last_name}!`);
    //         } catch (error) {
    //             console.log("Error getting current user:", error);
    //         }
    //     };

    //     getCurrentUsername();
    // }, []);

    return (
        <Box>
            {/* <Box>
                <Typography variant="h1" marginBottom={"12px"} gutterBottom>
                    Quick access
                </Typography>
                <Typography
                    variant="h5"
                    marginBottom={"12px"}
                    gutterBottom
                    sx={{
                    letterSpacing: -1
                }}>
                    {welcomeMessage}
                </Typography>
                <Typography variant="body2">
                    Quickly get to the tools you need to manage complaints
                </Typography>
            </Box> */}

            <Grid container spacing={3} >
                {Options.map((option, index) => (
                    <Grid item sm={12} md={6} lg={4} xl={3} key={index}>
                        <Box key={option.title}>

                            <Card variant="outlined">
                                <Box p={2}>
                                <Typography variant="h4">{option.title}</Typography>

                                </Box>
                            
                            <Divider />
                                <CardContent
                                    style={{
                                    width: "100%",
                                    minHeight: "250px",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between"
                                }}>
                                    
                                    <Box>
                                        
                                        <GradientBackground
                                            display="flex"
                                            alignItems="center"
                                            height={200}
                                            sx={{
                                            borderRadius: "0.4em",
                                            // backgroundColor: theme.palette.primary.main,
                                            background: 'linear-gradient(130deg, ${theme.palette.pwc.main} 10%, #e0e0e0 100%)',
                                            
                                        }}>
                                            {option.icon && <option.icon
                                                style={{
                                                color: theme.palette.background.default,
                                                fontSize: "8em",
                                                margin: "0 auto",
                                                // padding: "1px",
                                            }}/>}
                                        </GradientBackground>
                                        <Box my={2} minHeight={80}>
                                            <Typography variant="body1">{option.description}</Typography>
                                        </Box>
                                    </Box>

                                    <Box
                                        style={{
                                        textAlign: "right"
                                    }}>
                                        <Button variant="outlined">

                                            <Link
                                                href={option.navPath || ""}
                                                style={{
                                                textDecoration: "none",
                                            }}>
                                                {option.buttonLabel}
                                            </Link>

                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Home;
