import {
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Container,
  Divider,

  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,


} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

import { getFlagColor } from "../constants";
import BulletPointList from "../../../components/bulletpoints";
import HeadingWithIcon from "../../../components/HeadingWithIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import  HorizontalStackedBarChart from "./StackedBar";

// hardcoded for demo and testing. should come from complaints object
const data = [
  { label: 'Location', value: 5 },
  { label: 'Occupancy', value: 5 },
  { label: 'Coverage', value: 10 },
  { label: 'Country', value: 10 },
];

interface Props {
  complaint: any;
}

export const ExposureManagement = ({ complaint }: Props) => {
  const updatePriority = (newPriority: number) => {
    console.log("Updated priority to:", newPriority);
    // Implement the logic to update the priority, e.g., an API call
  };

  return (
    <Grid mt={3}>
      <Container maxWidth="md">

        <Card
          variant="outlined"
          style={{
            // padding: '8px',
            marginBottom: '24px',
            // minHeight: '300px'
          }}>
          <CardContent>
            <HeadingWithIcon
              icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
              variant="h6"
              fontWeight="bold">
              Exposure Management Details
            </HeadingWithIcon>
            <Divider
              sx={{
                mb: 2
              }} />

            <Grid container spacing={2}>

            <Grid item xs={12}>
                <Typography variant="subtitle2" fontWeight="bold">Exposure:</Typography>
              </Grid>
              <Grid item xs={12}>
              <Box width="100%">
                  <HorizontalStackedBarChart data={data}
                    current={25}
                    limit={40} />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">Red Flags:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{complaint?.submission_basic_checks?.RedFlags}</Typography>
              </Grid>

              

              <Grid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">Broker Priority:</Typography>
                <Typography variant="caption">(data from iHub)</Typography>
              </Grid>
              <Grid item xs={8}>
                <Chip size="small" label={complaint?.submission_basic_checks?.BrokerPriority ?? "N/A"} color={getFlagColor(complaint?.submission_basic_checks?.BrokerPriority)} />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" fontWeight="bold">Risk Management Score:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Chip size="small" label={complaint?.submission_basic_checks?.RiskManagementScore ?? "N/A"} color={getFlagColor(complaint?.submission_basic_checks?.RiskManagementScore)} />

              </Grid>

              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="summary-content"
                    id="summary-header"
                  >
                    {/* <Typography variant="subtitle2" fontWeight="bold">Risk Summary</Typography> */}
                    <HeadingWithIcon icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />} variant="subtitle2" fontWeight="bold">
                    Risk Summary
                  </HeadingWithIcon>

                  </AccordionSummary>
                  <AccordionDetails>
                    <BulletPointList
                      paragraph={complaint.submission_basic_checks && complaint.submission_basic_checks.Summary
                        ? complaint.submission_basic_checks.Summary
                        : 'TBA'}
                      listStyle="default"
                    />
                  </AccordionDetails>
                </Accordion>

              </Grid>
            </Grid>

          </CardContent>
        </Card>


        <Card
          variant="outlined"
          style={{
            padding: "8px",
            minHeight: "300px",
          }}
        >
          <CardContent>
            <HeadingWithIcon
              icon={<AutoAwesomeIcon color="primary" fontSize="inherit" />}
              variant="h6"
              fontWeight="bold"
            >
              Exposure Management Summary:
            </HeadingWithIcon>
            <Divider
              sx={{
                mb: 2,
              }}
            />
            <BulletPointList
              paragraph={
                complaint.submission_summary &&
                  complaint.submission_summary.Summary
                  ? complaint.submission_summary.Summary
                  : "TBA"
              }
              listStyle="bullet"
            />
          </CardContent>
        </Card>
      </Container>
    </Grid>
  );
};
