import React, { useState } from "react";
import { Button, Grid, CircularProgress } from "@mui/material";
import TechnicalPricingDetailsCard from "./TechnicalPricingDetailsCard";
import TechnicalPricingPricedCard from "./TechnicalPricingPricedCard";

interface YourComponentProps {
  complaint: any; // Replace 'any' with the actual type of 'complaint' if known
}

export const TechnicalPricing: React.FC<YourComponentProps> = ({
  complaint,
}) => {
  const [showPricedCard, setShowPricedCard] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowPricedCard(true);
    }, 2000);
  };

  return (
    <Grid container spacing={3} mt={0} justifyContent="space-between">
      {/* case details main sections */}
      <Grid item xs={12} md={12}>
        <TechnicalPricingDetailsCard complaint={complaint} />
      </Grid>
      {/* Submit button */}
      <Grid item xs={12} md={12} container justifyContent="center" mt={2}>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Grid>
      {/* Conditionally render TechnicalPricingPricedCard */}
      {showPricedCard && (
        <Grid item xs={12} md={12} mt={2}>
          <TechnicalPricingPricedCard complaint={complaint} />
        </Grid>
      )}
    </Grid>
  );
};
