import React, { useState, useEffect } from 'react';
import { 
  Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Button, 
  Card, CardContent, Typography, CardActions, Dialog, DialogActions, DialogContent, DialogTitle 
} from '@mui/material';
import { fetchAllCustomers } from '../../../api';
import PrioritySlider from '../../submission-details/components/PrioritySlider';

interface ManageSubmissionProps {
  updatePriority: (newPriority: number) => void;
  showPrioritySlider: boolean;
}

const ManageSubmission: React.FC<ManageSubmissionProps> = ({ updatePriority, showPrioritySlider }) => {
  const [customers, setCustomers] = useState<string[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<string>('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      const result = await fetchAllCustomers();
      setCustomers(result);
    };
    fetchCustomers();
  }, []);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedCustomer(event.target.value as string);
  };

  const handleSaveChanges = () => {
    console.log(`Assigned to: ${selectedCustomer}`);
    // Implement the logic to save changes here
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} size='small' style={{textTransform: "none"}}>
        Manage
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Manage submission</DialogTitle>
        <DialogContent>
          <Card variant="outlined" style={{ padding: '0px' }}>
            <CardContent>
              <Typography variant="subtitle1" gutterBottom>
                Assign to:
              </Typography>
              <Box sx={{ minWidth: 120, mt: 2 }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="customer-select-label">Assignee</InputLabel>
                  <Select
                    labelId="customer-select-label"
                    id="customer-select"
                    value={selectedCustomer}
                    label="Assignee"
                    onChange={handleChange}
                  >
                    {customers.map((customer) => (
                      <MenuItem key={customer} value={customer}>
                        {customer}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {showPrioritySlider && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Update priority:
                  </Typography>
                  <PrioritySlider complaint={{}} updatePriority={updatePriority} showDialog={false} />
                </Box>
              )}
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveChanges}
                disabled={!selectedCustomer || !updatePriority}
                fullWidth
              >
                Save changes
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageSubmission;