// AppSideBar.tsx
import React, { useMemo } from "react";
import {
  Box,
  Toolbar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider,
  Drawer,
  useTheme
} from "@mui/material";
import { sidebarConfig, IconName, SidebarItem } from "../config/sidebarConfig";
import { ExpandLess, ExpandMore, Home, PendingActions, Segment, Settings, DisplaySettings } from "@mui/icons-material";

export const AppSideBarWidth: number = 300;

const iconMap: Record<IconName, React.ElementType> = {
  Home,
  PendingActions,
  Segment,
  Settings,
  DisplaySettings
};

export const AppSideBar = () => {
  const theme = useTheme();
  // Pre-compute the initial open sections state
  const initialOpenSections = useMemo(() => 
    sidebarConfig.reduce((acc, section) => {
      if (section.collapsible) {
        acc[section.title] = section.defaultOpen || false;
      }
      return acc;
    }, {} as { [key: string]: boolean }),
  []);

  const [openSections, setOpenSections] = React.useState(initialOpenSections);

  const toggleCollapse = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  const renderIcon = (iconName: IconName) => {
    const IconComponent = iconMap[iconName];
    return <IconComponent sx={{ color: "#252525" }} />;
  };

  const renderSidebarItem = (section: SidebarItem, index: number) => (
    <div key={index}>
      {section.collapsible ? (
        <>
          <ListItemButton onClick={() => toggleCollapse(section.title)}>
            <ListItemIcon sx={{ minWidth: "0", paddingRight: "10px" }}>
              {renderIcon(section.icon)}
            </ListItemIcon>
            <ListItemText primary={section.title} primaryTypographyProps={{ style: { fontSize: "0.9em" } }} />
            {openSections[section.title] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSections[section.title]} timeout="auto">
            <List component="div" disablePadding sx={{ "& .MuiListItemButton-root": { pl: 2, ml: 5, mr: 2, fontSize: "0.9em", borderRadius: "5px", "&:hover": { backgroundColor: theme.palette.primary.main, color: theme.palette.background.default } }, "& .MuiListItemText-root": { marginTop: "2px", marginBottom: "2px" } }}>
              {section.items?.map((item, idx) => (
                <ListItemButton key={idx} href={item.href} disabled={item.disabled}>
                  <ListItemText primary={item.name} primaryTypographyProps={{ style: { fontSize: "0.9em" } }} />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <ListItemButton href={section.link || "/"}>
          <ListItemIcon sx={{ minWidth: "0", paddingRight: "10px" }}>
            {renderIcon(section.icon)}
          </ListItemIcon>
          <ListItemText primary={section.title} primaryTypographyProps={{ style: { fontSize: "0.9em" } }} />
        </ListItemButton>
      )}
    </div>
  );

  return (
    <Drawer
      variant="permanent"
      sx={{
        // bgcolor: theme.palette.background.default,
        borderRight: "none",
        width: AppSideBarWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paperAnchorDockedLeft`]: {
          borderRight: 0,
        },
        [`& .MuiDrawer-paper`]: {
          width: AppSideBarWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List component="nav" sx={{ padding: "8px", margin: "32px 20px", height: "auto", bgcolor: theme.palette.sidebar.light, boxShadow: "0 0 0 0", color: "#252525", borderRadius: "8px" }}>
          <Box p={1}>
            <Typography variant="body2" gutterBottom sx={{ letterSpacing: 0 }}>
              Welcome, User!
            </Typography>
          </Box>
          <Divider />
          {sidebarConfig.map(renderSidebarItem)}
        </List>
      </Box>
    </Drawer>
  );
};